<script>
    import { onMount } from "svelte";

    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.has("lang")
        ? urlParams.get("lang")
        : navigator.language || navigator.userLanguage;
    if (!["de", "fr", "en"].includes(lang)) {
        urlParams.set("lang", "en");
        window.location.replace("/create?" + urlParams.toString());
    }

    function setLanguage(newLang) {
        if (!["de", "fr", "en"].includes(lang)) return;
        urlParams.set("lang", newLang);
        window.location.replace("/create?" + urlParams.toString());
    }

    let negLang = lang;
    $: console.log(negLang);

    let soloMode = false;

    let t = {};

    onMount(() => {
        fetch("/static/i18n.json")
            .then((res) => res.json())
            .then((j) => (t = j[lang]));
    });

    let summary;
    let parties = [
        {
            id: 1,
            name: "",
            email: "",
        },
        {
            id: 2,
            name: "",
            email: "",
        },
    ];
    let runningPartyId = 3;
    function removeParty(partyIndex) {
        parties = [
            ...parties.slice(0, partyIndex),
            ...parties.slice(partyIndex + 1),
        ];
    }
    function addParty() {
        if (parties.length >= 4) {
            alert(t.createPartyLimit);
            return;
        }
        parties = [
            ...parties,
            {
                id: runningPartyId,
                name: "",
                email: "",
            },
        ];
        runningPartyId++;
    }
    function createNegotiation() {
        document.getElementById("createButton").disabled = true;
        fetch(
            "/api/create?" +
                new URLSearchParams(
                    {
                        summary,
                        parties: JSON.stringify(parties),
                        lang: negLang,
                        soloMode: soloMode,
                    },
                    { signal: AbortSignal.timeout(50000) },
                ),
        )
            .then((res) => res.json())
            .then((j) => {
                if (!j.success) {
                    alert(j.msg);
                    return;
                }
                window.location.replace(
                    `/overview?uuid=${j.uuid}&lang=${lang}`,
                );
            });
    }
</script>

<main class="flex flex-col items-center bg-base w-full min-h-screen p-4">
    <select
        class="absolute top-2 right-4 p-2 bg-base text-textcol border border-accent rounded-lg
		cursor-pointer"
        on:change={(e) => setLanguage(e.target.value)}
    >
        <option value="de" selected={lang === "de"}>Deutsch</option>
        <option value="fr" selected={lang === "fr"}>Français</option>
        <option value="en" selected={lang === "en"}>English</option>
    </select>
    <div class="flex flex-col items-start md:landscape:w-2/3">
        <h1 class="font-bold text-2xl my-4 text-textcol">{t.createHeader}</h1>

        <h2 class="font-bold text-xl mt-4 mb-2 text-textcol">
            {t.createLangHeader}
        </h2>
        <p class="mb-2 text-textcol">{t.createLangDisclaimer}</p>
        <select
            class="my-2 p-2 bg-base text-textcol border border-accent rounded-lg
		cursor-pointer"
            bind:value={negLang}
        >
            <option value="de" selected={lang === "de"}>Deutsch</option>
            <option value="fr" selected={lang === "fr"}>Français</option>
            <option value="en" selected={lang === "en"}>English</option>
        </select>

        <h2 class="font-bold text-xl mt-4 mb-2 text-textcol">
            {t.createTopicHeader}
        </h2>
        <p class="mb-2 text-textcol">{t.createTopicDisclaimer}</p>
        <textarea
            class="p-2 rounded-md w-full h-28 bg-elevated text-textcol placeholder-textcol"
            placeholder={t.createTopicPlaceholder}
            bind:value={summary}
        ></textarea>

        <h2 class="font-bold text-xl mt-4 mb-2 text-textcol">
            {t.createPartyHeader}
        </h2>
        <p class="my-2 text-textcol">{t.createSoloPar}</p>
        <div class="flex flex-row items-center text-textcol my-2">
            <p class="font-bold">{t.createSoloModeLabel}</p>
            <input
                type="checkbox"
                class="ml-2 checkbox checkbox-accent"
                bind:checked={soloMode}
            />
        </div>
        {#each parties as party, i (party.id)}
            <div
                class="flex flex-col md:landscape:flex-row items-start md:landscape:items-center justify-center mb-2"
            >
                <p class="font-bold text-textcol">{t.createParty} {i + 1}:</p>
                <input
                    class="mt-2 md:landscape:mt-0 md:landscape:mx-2 p-2 h-12 rounded-md bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.createName}
                    bind:value={parties[i].name}
                />
                {#if !soloMode || i === 0}
                    <input
                        class="mt-2 md:landscape:mt-0 md:landscape:mx-2 p-2 h-12 rounded-md bg-elevated text-textcol placeholder-textcol"
                        placeholder={t.createEmail}
                        bind:value={parties[i].email}
                    />
                {/if}
                <button
                    hidden={parties.length <= 2}
                    class="mt-2 md:landscape:mt-0 btn text-base font-normal rounded-md h-12 w-12 p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol"
                    on:click={removeParty(i)}
                >
                    <i class="fa-solid fa-trash text-textcol"></i>
                </button>
            </div>
        {/each}
        <button
            hidden={parties.length > 3}
            class="btn text-base font-normal rounded-md h-12 p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol flex items-center"
            on:click={addParty}
        >
            <i class="fa-solid fa-user-plus text-textcol"></i>
            <p class="ml-2 text-textcol">{t.createAddParty}</p>
        </button>

        <button
            class="btn text-base font-normal mt-4 p-2 h-12 rounded-md bg-accent border border-accent text-textsec hover:bg-elevated hover:text-textcol place-self-center"
            id="createButton"
            on:click={createNegotiation}
        >
            <i class="fa-solid fa-handshake text-inherit"></i>
            <p class="ml-2 text-inherit">{t.createButton}</p>
        </button>
    </div>
</main>
