<script>
    import { onMount } from "svelte";

    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.has("lang")
        ? urlParams.get("lang")
        : navigator.language || navigator.userLanguage;
    if (!["de", "fr", "en"].includes(lang)) {
        urlParams.set("lang", "en");
        window.location.replace("/position?" + urlParams.toString());
    }

    function setLanguage(newLang) {
        if (!["de", "fr", "en"].includes(lang)) return;
        urlParams.set("lang", newLang);
        window.location.replace("/position?" + urlParams.toString());
    }

    let t = {};
    let soloMode = false;

    const uuid = urlParams.get("uuid");
    const partyNum = parseInt(urlParams.get("pnum"));
    let simplePos = true;
    let simplePosText;

    let formFieldContent = {
        area: [
            {
                id: 1,
                text: t.posArea1,
            },
            {
                id: 2,
                text: t.posArea2,
            },
            {
                id: 3,
                text: t.posArea3,
            },
        ],
        priorities: [
            {
                id: 1,
                points: 100,
                text: "",
            },
        ],
        relationshipGrade: [
            {
                id: 1,
                text: t.posRG1,
            },
            {
                id: 2,
                text: t.posRG2,
            },
            {
                id: 3,
                text: t.posRG3,
            },
            {
                id: 4,
                text: t.posRG4,
            },
        ],
    };
    let pointOverhead = false;
    $: {
        if (
            formFieldContent.priorities.reduce(
                (partialSum, a) => partialSum + a.points,
                0,
            ) > 100
        ) {
            pointOverhead = true;
        }
    }
    let partyPosition = {
        importanceRelationship: 5,
        solutionUrgency: 5,
        conflictSeverity: 5,
    };
    let otp = "";

    let status = {};

    function addPriority() {
        formFieldContent.priorities = [
            ...formFieldContent.priorities,
            {
                id: formFieldContent.priorities.length + 1,
                points: 0,
                text: "",
            },
        ];
    }

    function checkPointMax(prioPoints, prioIndex) {
        let overhead =
            formFieldContent.priorities.reduce(
                (partialSum, a) => partialSum + a.points,
                0,
            ) - 100;
        if (!overhead || overhead <= 0) return;
        formFieldContent.priorities[prioIndex].points = prioPoints - overhead;
    }

    function checkAddPrio(event) {
        if (event.key !== "Enter") return;
        addPriority();
    }

    onMount(() => {
        fetch("/static/i18n.json")
            .then((res) => res.json())
            .then((j) => {
                t = j[lang];
                formFieldContent = {
                    ...formFieldContent,
                    relationshipGrade: [
                        {
                            id: 1,
                            text: t.posRG1,
                        },
                        {
                            id: 2,
                            text: t.posRG2,
                        },
                        {
                            id: 3,
                            text: t.posRG3,
                        },
                        {
                            id: 4,
                            text: t.posRG4,
                        },
                    ],
                    area: [
                        {
                            id: 1,
                            text: t.posArea1,
                        },
                        {
                            id: 2,
                            text: t.posArea2,
                        },
                        {
                            id: 3,
                            text: t.posArea3,
                        },
                    ],
                };
            });
        fetch(
            "/api/status?" +
                new URLSearchParams({
                    uuid,
                }),
        )
            .then((res) => res.json())
            .then((j) => {
                console.log(j);
                if (j.neg_info[3] > 0) {
                    alert(t.posNegStarted);
                    return;
                }

                status.topic = j.neg_info[1];
                status.partyInfo = JSON.parse(j.neg_info[2])[partyNum - 1];
                soloMode = j.neg_info[4];
            });
    });

    function checkForNegotiationStart() {
        fetch(
            "/api/status?" +
                new URLSearchParams({
                    uuid,
                }),
        )
            .then((res) => res.json())
            .then((j) => {
                if (j.neg_info[3] > 0) {
                    alert(t.posRedirect);
                    window.location.replace(`/overview?uuid=${uuid}`);
                }
            });
    }

    function setPartyPosition() {
        document.getElementById("positionButton").disabled = true;
        let fullPosition;
        if (simplePos) {
            fullPosition = {
                simplePosText,
            };
        } else {
            fullPosition = {
                ...partyPosition,
                priorities: formFieldContent.priorities,
            };
            fullPosition.area = fullPosition.area.text;
            fullPosition.relationshipGrade =
                fullPosition.relationshipGrade.text;
        }

        fetch(
            "/api/setposition?" +
                new URLSearchParams({
                    uuid,
                    party_num: partyNum,
                    otp: otp.trim(),
                    position: JSON.stringify(fullPosition),
                }),
        )
            .then((res) => res.json())
            .then((j) => {
                if (j.success) {
                    alert(t.posRedirectAfterSet);
                    window.location.replace(
                        `/overview?uuid=${uuid}&lang=${lang}`,
                    );
                } else {
                    alert(j.msg);
                }
            });
        setTimeout(checkForNegotiationStart, 5000);
    }
</script>

<main class="flex flex-col w-screen min-h-screen bg-base p-4">
    <select
        class="absolute top-2 right-4 p-2 bg-base text-textcol border border-accent rounded-lg
		cursor-pointer"
        on:change={(e) => setLanguage(e.target.value)}
    >
        <option value="de" selected={lang === "de"}>Deutsch</option>
        <option value="fr" selected={lang === "fr"}>Français</option>
        <option value="en" selected={lang === "en"}>English</option>
    </select>
    <h1 class="font-bold text-2xl mt-4 text-textcol">{t.posHeader}</h1>
    <p class="text-textcol mt-2">{t.ovUUID} {uuid}</p>
    {#if status.partyInfo}
        <p class="font-bold mt-4 text-textcol">
            {t.posPartyPre}
            {status.partyInfo.name}
            {#if status.partyInfo.email}({status.partyInfo.email}){/if}
        </p>
        <p class="font-bold mt-4 text-textcol">{t.ovTopicHeader}</p>
        <p class="text-textcol">{status.topic}</p>

        <h2 class="font-bold text-xl mt-4 mb-2 text-textcol">
            {t.posSetHeader}
        </h2>

        <div class="flex flex-col w-full md:landscape:w-2/3 place-self-center">
            <div
                class="flex flex-row w-full justify-center items-center"
                title={t.posSimpleModeExplain}
            >
                <p class="text-textcol">{t.posCompleteMode}</p>
                <input
                    type="checkbox"
                    bind:checked={simplePos}
                    class="ml-2 toggle toggle-md bg-accent hover:bg-elevated"
                />
                <p class="ml-2 text-textcol">{t.posSimpleMode}</p>
            </div>
            {#if simplePos}
                <h3 class="font-bold text-xl mt-4 mb-2 text-textcol">
                    {t.posShortDesc}
                </h3>
                <p class="mt-2 text-textcol">{t.posShortDescP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-64 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posShortDescPH}
                    bind:value={simplePosText}
                    maxlength="30000"
                ></textarea>
            {:else}
                <h3 class="font-bold text-xl mt-4 mb-2 text-textcol">
                    {t.posAreaHeader}
                </h3>
                <p class="mt-2 text-textcol">{t.posAreaP}</p>
                <select
                    class="p-2 text-textcol bg-elevated"
                    bind:value={partyPosition.area}
                >
                    {#each formFieldContent.area as a}
                        <option value={a}>{a.text}</option>
                    {/each}
                </select>

                <h3 class="font-bold text-xl mt-4 mb-2 text-textcol">
                    {t.posBasicHeader}
                </h3>
                <p class="mt-2 text-textcol">{t.posBasicP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-28 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posBasicPH}
                    bind:value={partyPosition.mainReason}
                    maxlength="300"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posBeginP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posBeginPH}
                    bind:value={partyPosition.startDate}
                    maxlength="300"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posPriosP}</p>
                {#each formFieldContent.priorities as prio, i}
                    <div class="flex flex-row items-center mt-2">
                        <input
                            class="p-2 rounded-md w-full h-12 bg-elevated text-textcol placeholder-textcol"
                            placeholder={t.posPriosPH}
                            on:keyup={checkAddPrio}
                            bind:value={prio.text}
                            autofocus={i > 0 &&
                                i === formFieldContent.priorities.length - 1}
                        />
                        <p class="mx-2 text-textcol">{t.posPoints}</p>
                        <input
                            class="p-2 rounded-md text-textcol bg-elevated"
                            type="number"
                            min="0"
                            max="100"
                            bind:value={prio.points}
                            on:input={checkPointMax(prio.points, i)}
                        />
                    </div>
                {/each}
                <button
                    hidden={formFieldContent.priorities.length > 4}
                    class="btn text-base font-normal rounded-md p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol mt-2"
                    on:click={addPriority}
                >
                    <i class="fa-solid fa-user-plus text-textcol"></i>
                    <p class="ml-2 text-textcol">{t.posAddPrio}</p>
                </button>

                <h3 class="font-bold text-xl mt-4 mb-2 text-textcol">
                    {t.posIntHeader}
                </h3>
                <p class="mt-2 text-textcol">{t.posIntP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posIntPH}
                    bind:value={partyPosition.mainInterests}
                    maxlength="200"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posImpP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posImpPH}
                    bind:value={partyPosition.mainInterestsReasons}
                    maxlength="300"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posNonNegP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posNonNegPH}
                    bind:value={partyPosition.nonNegotiable}
                    maxlength="200"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posMinP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posMinPH}
                    bind:value={partyPosition.zopa}
                    maxlength="200"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posAbortReasonsP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posAbortReasonsPH}
                    bind:value={partyPosition.nogos}
                    maxlength="200"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posCommonIP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posCommonIPH}
                    bind:value={partyPosition.commonInterests}
                    maxlength="300"
                ></textarea>

                <h3 class="font-bold text-xl mt-4 mb-2 text-textcol">
                    {t.posEmoHeader}
                </h3>
                <p class="mt-2 text-textcol">{t.posEmoP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posEmoPH}
                    bind:value={partyPosition.emotionalFactors}
                    maxlength="300"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posKeepP}</p>
                <input
                    type="range"
                    min="1"
                    max="10"
                    bind:value={partyPosition.importanceRelationship}
                />
                <p class="mt-2 text-textcol">{t.posRelGradeP}</p>
                <select
                    class="p-2 text-textcol bg-elevated"
                    bind:value={partyPosition.relationshipGrade}
                >
                    {#each formFieldContent.relationshipGrade as g}
                        <option value={g}>{g.text}</option>
                    {/each}
                </select>

                <h3 class="font-bold text-xl mt-4 mb-2 text-textcol">
                    {t.posAltHeader}
                </h3>
                <p class="mt-2 text-textcol">{t.posBAltP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posBAltPH}
                    bind:value={partyPosition.batna}
                    maxlength="300"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posWAltP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posWAltPH}
                    bind:value={partyPosition.watna}
                    maxlength="300"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posRAltP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posRAltPH}
                    bind:value={partyPosition.ratna}
                    maxlength="300"
                ></textarea>

                <h3 class="font-bold text-xl mt-4 mb-2 text-textcol">
                    {t.posUrgencyHeader}
                </h3>
                <p class="mt-2 text-textcol">{t.posUrgencyP}</p>
                <input
                    type="range"
                    min="1"
                    max="10"
                    bind:value={partyPosition.solutionUrgency}
                    class="p-2 text-textcol"
                />
                <p class="mt-2 text-textcol">{t.posEscP}</p>
                <input
                    type="range"
                    min="1"
                    max="10"
                    bind:value={partyPosition.conflictSeverity}
                    class="p-2 text-textcol"
                />
                <p class="mt-2 text-textcol">{t.posPastEscP}</p>
                <select
                    class="p-2 text-textcol bg-elevated"
                    bind:value={partyPosition.escalation}
                >
                    <option value={t.posYes}>{t.posYes}</option>
                    <option value={t.posNo}>{t.posNo}</option>
                    <option value={t.posPartly}>{t.posPartly}</option>
                </select>
                <p class="mt-2 text-textcol">{t.posStepsP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posStepsPH}
                    bind:value={partyPosition.previousSteps}
                    maxlength="400"
                ></textarea>

                <h3 class="font-bold text-xl mt-4 mb-2 text-textcol">
                    {t.posPastHeader}
                </h3>
                <p class="mt-2 text-textcol">{t.posPastP}</p>
                <select
                    class="p-2 text-textcol bg-elevated"
                    bind:value={partyPosition.previousConflicts}
                >
                    <option value={t.posYes}>{t.posYes}</option>
                    <option value={t.posNo}>{t.posNo}</option>
                </select>
                <p class="mt-2 text-textcol">{t.posConflictSolveP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posConflictSolvePH}
                    bind:value={partyPosition.previousSolutions}
                    maxlength="400"
                ></textarea>

                <h3 class="font-bold text-xl mt-4 mb-2 text-textcol">
                    {t.posSolHeader}
                </h3>
                <p class="mt-2 text-textcol">{t.posSolP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posSolPH}
                    bind:value={partyPosition.ownSteps}
                    maxlength="400"
                ></textarea>
                <p class="mt-2 text-textcol">{t.posWishP}</p>
                <textarea
                    class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posWishPH}
                    bind:value={partyPosition.wish}
                    maxlength="200"
                ></textarea>
            {/if}

            {#if !soloMode}
                <h3 class="font-bold text-xl mt-4 mb-2 text-textcol">
                    {t.posAuth}
                </h3>
                <p class="mt-2 text-textcol">{t.posAuthP}</p>
                <input
                    type="password"
                    class="p-2 rounded-md w-full h-12 bg-elevated text-textcol placeholder-textcol"
                    placeholder={t.posAuthPH}
                    bind:value={otp}
                />
            {/if}
        </div>
        <button
            class="btn text-base font-normal mt-4 p-2 h-12 rounded-md bg-accent border border-accent text-textsec hover:bg-elevated hover:text-textcol place-self-center"
            id="positionButton"
            on:click={setPartyPosition}
        >
            <i class="fa-solid fa-paper-plane text-textsec"></i>
            <p class="ml-2 text-textsec">{t.posSetPos}</p>
        </button>
    {:else}
        <p class="text-textcol">{t.ovLoading}</p>
    {/if}
</main>
