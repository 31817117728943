<script>
	import { onMount } from "svelte";

	const urlParams = new URLSearchParams(window.location.search);
	const lang = urlParams.has("lang") ? 
		urlParams.get("lang") :
		navigator.language || navigator.userLanguage;
	if(!["de", "fr", "en"].includes(lang)) {
		urlParams.set("lang", "en");
		window.location.replace("/?" + urlParams.toString());
	}

	function setLanguage(newLang) {
		if(!["de", "fr", "en"].includes(lang)) return
		urlParams.set("lang", newLang);
		window.location.replace("/?" + urlParams.toString());
	}

	let t = {};

	onMount(() => {
		fetch("/static/i18n.json").then(res => res.json())
			.then(j => t = j[lang]);
	});

	let uu;
	function redirectToOverview() {
		window.location.replace(`/overview?uuid=${uu}`);
	}
	function redirectOnEnter(event) {
		if(event.key === "Enter") {
			redirectToOverview()
		}
 	}
</script>

{#if t.homeH1}
	<main class="flex flex-col items-center bg-base min-h-screen m-0 p-4">
		<select class="absolute top-2 right-4 p-2 bg-base text-textcol border border-accent rounded-lg
			cursor-pointer"
			on:change={e => setLanguage(e.target.value)}>
			<option value="de" selected={lang === "de"}>Deutsch</option>
			<option value="fr" selected={lang === "fr"}>Français</option>
			<option value="en" selected={lang === "en"}>English</option>
		</select>
		<div class="w-full md:landscape:w-2/3">
			<h1 class="font-bold text-2xl my-4 text-textcol">GetYourDeal.ai</h1>
		
			<h2 class="font-bold text-xl mt-4 mb-2 text-textcol place-self-start">{t.homeH1}</h2>
			<p class="text-textcol text-justify">{@html t.homeP1}</p>

			<h2 class="font-bold text-xl mt-4 mb-2 text-textcol place-self-start">{t.homeH2}</h2>
			<p class="text-textcol text-justify">{@html t.homeP2}</p>

			<h2 class="font-bold text-xl mt-4 mb-2 text-textcol place-self-start">{t.homeCreateHeader}</h2>
			<div class="btn p-2 h-12 rounded-md bg-accent border border-accent text-textsec hover:bg-elevated hover:text-textcol place-self-start text-base font-normal w-full md:landscape:w-auto flex flex-row justify-center">
				<a href={"/create?lang=" + lang} class="text-textcol h-full w-full flex flex-row items-center justify-center" style="text-decoration: none; color: inherit;">
					{t.homeCreateButton}
				</a>
			</div>
			
			<h2 class="font-bold text-xl mt-4 mb-2 text-textcol place-self-start">{t.homeOverviewHeader}</h2>
			<div class="flex flex-col md:landscape:flex-row items-center place-self-start w-full">
				<input 
				bind:value={uu}
				class="p-2 h-12 w-full md:landscape:w-2/3 rounded-md flex-grow bg-elevated text-textcol placeholder-textcol"
				placeholder={t.homeOverviewPlaceholder}
				on:keyup={redirectOnEnter}/>
				<button class="btn rounded-md p-2 bg-base border border-accent hover:bg-elevated hover:text-textcol mt-2 md:landscape:mt-0 md:landscape:ml-2 w-full md:landscape:w-auto font-normal flex flex-row justify-center text-base"
				on:click={redirectToOverview}>
					<p class="text-textcol">{t.homeOverviewButton}</p>
				</button>
			</div>
		</div>
	</main>
{/if}
