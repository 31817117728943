<script>
    import { Router, Link, Route } from "svelte-routing";
    import Home from "./routes/Home.svelte";
    import Prompt from "./routes/Prompt.svelte";
    import Create from "./routes/Create.svelte";
    import Position from "./routes/Position.svelte";
    import Approval from "./routes/Approval.svelte";
    import Overview from "./routes/Overview.svelte";

    export let url = "";
</script>

<Router {url} class="bg-base w-full">
    <div class="bg-base w-full">
        <Route path="/create" component={Create} />
        <Route path="/position" component={Position} />
        <Route path="/approval" component={Approval} />
        <Route path="/overview" component={Overview} />
        <Route path="/"><Home /></Route>
        <Route path="/prompt" component={Prompt} />
    </div>
</Router>
