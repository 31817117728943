<script>
    import { onMount } from "svelte";

    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.has("lang")
        ? urlParams.get("lang")
        : navigator.language || navigator.userLanguage;
    if (!["de", "fr", "en"].includes(lang)) {
        urlParams.set("lang", "en");
        window.location.replace("/overview?" + urlParams.toString());
    }

    function setLanguage(newLang) {
        if (!["de", "fr", "en"].includes(lang)) return;
        urlParams.set("lang", newLang);
        window.location.replace("/overview?" + urlParams.toString());
    }

    let t = {};
    const uuid = urlParams.get("uuid");
    let hasProposal = false;

    let status = {};
    let chatMessages = [];

    onMount(() => {
        fetch("/static/i18n.json")
            .then((res) => res.json())
            .then((j) => (t = j[lang]));
        fetch(
            "/api/status?" +
                new URLSearchParams({
                    uuid,
                }),
        )
            .then((res) => res.json())
            .then((j) => {
                console.log(j);
                status.topic = j.neg_info[1];
                status.stage = j.neg_info[3];
                hasProposal = j.has_proposal;
                status.parties = JSON.parse(j.neg_info[2]);
                j.party_positions.forEach((hasPosition, partyIndex) => {
                    status.parties[partyIndex].hasPosition = hasPosition;
                });
                j.party_approvals.forEach((pa, partyIndex) => {
                    if (pa !== "" && status.stage !== 1) {
                        status.parties[partyIndex].approval = JSON.parse(pa);
                    }
                });
                console.log(status);
                if (status.stage > 0) {
                    checkMessages();
                }
            });
    });

    function checkMessages() {
        console.log("checking messages...");
        fetch(
            "/api/llmlog?" +
                new URLSearchParams({
                    uuid,
                }),
        )
            .then((res) => res.json())
            .then((j) => {
                chatMessages = JSON.parse(j[1]);
                status.stage = j[2];
                if (status.stage === 1) {
                    setTimeout(checkMessages, 5000);
                }
            });
    }
</script>

<main class="flex flex-col items-center bg-base w-full min-h-screen p-4 m-0">
    <select
        class="absolute top-2 right-4 p-2 bg-base text-textcol border border-accent rounded-lg
		cursor-pointer"
        on:change={(e) => setLanguage(e.target.value)}
    >
        <option value="de" selected={lang === "de"}>Deutsch</option>
        <option value="fr" selected={lang === "fr"}>Français</option>
        <option value="en" selected={lang === "en"}>English</option>
    </select>
    <div class="flex flex-col items-start md:landscape:w-2/3">
        <h1 class="font-bold text-2xl mt-4 text-textcol">{t.ovHeader}</h1>
        <p class="text-textcol mt-2">{t.ovUUID} {uuid}</p>

        {#if Object.keys(status).length > 0}
            <h2 class="font-bold text-xl mt-4 mb-2 text-textcol">
                {t.createPartyHeader}
            </h2>
            {#each status.parties as party, partyIndex (party.id)}
                <div class="flex items-center py-2">
                    <p class="text-textcol">
                        {t.createParty}
                        {partyIndex + 1}:
                        {party.name}
                        {#if party.email}
                            ({party.email}){/if}
                    </p>
                    {#if status.stage === 0}
                        <div
                            class="ml-2"
                            hidden={!party.hasPosition}
                            title={t.ovPositionSubmitted}
                        >
                            <i class="fa-solid fa-circle-check text-green-200"
                            ></i>
                        </div>
                        <div
                            class="btn text-base font-normal rounded-md p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol ml-2"
                        >
                            <a
                                href={"/position?" +
                                    new URLSearchParams({
                                        uuid,
                                        pnum: partyIndex + 1,
                                        lang: lang,
                                    })}
                                class="text-textcol"
                                style="text-decoration: none; color: white;"
                                >{t.ovSetPosition}</a
                            >
                        </div>
                    {:else if status.stage === 2}
                        {#if party.approval}
                            {#if party.approval.approval}
                                <div class="ml-2" title={t.ovPartyApproved}>
                                    <i
                                        class="fa-solid fa-circle-check text-green-200"
                                    ></i>
                                </div>
                            {:else}
                                <div class="ml-2" title={t.ovPartyDeclined}>
                                    <i
                                        class="fa-solid fa-circle-xmark text-red-200"
                                    ></i>
                                </div>
                            {/if}
                        {/if}
                        <div
                            class="btn text-base font-normal rounded-md p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol ml-2"
                        >
                            <a
                                href={"/approval?" +
                                    new URLSearchParams({
                                        uuid,
                                        pnum: partyIndex + 1,
                                        lang: lang,
                                    })}
                                class="text-textcol"
                                style="text-decoration: none; color: white;"
                                >{t.ovRate}</a
                            >
                        </div>
                    {:else if status.stage === 3}
                        <div class="ml-2" title={t.ovPartyApproved}>
                            <i class="fa-solid fa-circle-check text-green-200"
                            ></i>
                        </div>
                    {/if}
                </div>
            {/each}

            <h2 class="font-bold text-xl mt-4 mb-2 text-textcol">
                {t.ovTopicHeader}
            </h2>
            <p class="text-textcol">{status.topic}</p>

            <h2 class="font-bold text-xl mt-4 mb-2 text-textcol">
                {t.ovNegState}
            </h2>
            {#if status.stage === 0}
                <p class="text-textcol">{t.ovStage0}</p>
            {:else if status.stage === 3 && hasProposal}
                <a href={`/static/docx/${uuid}.docx`}>
                    <div
                        class="btn text-base font-normal rounded-md p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol"
                    >
                        {t.ovDownloadButton}
                    </div>
                </a>
            {/if}

            {#if status.stage >= 1}
                {#each chatMessages as msg}
                    <p class="font-bold text-textcol mt-8">
                        {t.ovNegOf}
                        {msg.party}
                    </p>
                    <p class="text-textcol text-justify">
                        {@html msg.msg
                            .replaceAll("#AGREE#", "")
                            .replaceAll("#FAILED#", "")
                            .trim()
                            .replaceAll("\n", "<br/>")}
                    </p>
                    {#if msg.msg.trim() === "#AGREE#"}
                        <p class="text-green-200 text-justify">
                            {t.ovNegAgreeMsg.replace("#PARTY#", msg.party)}
                        </p>
                    {:else if msg.msg.trim() === "#FAILED#"}
                        <p class="text-red-200 text-justify">
                            {t.ovNegDeclineMsg.replace("#PARTY#", msg.party)}
                        </p>
                    {/if}
                {/each}
            {/if}
        {:else}
            <p class="text-textcol">{t.ovLoading}</p>
        {/if}
    </div>
</main>
