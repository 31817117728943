<script>
    import { onMount } from "svelte";

    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.has("lang")
        ? urlParams.get("lang")
        : navigator.language || navigator.userLanguage;
    if (!["de", "fr", "en"].includes(lang)) {
        urlParams.set("lang", "en");
        window.location.replace("/approval?" + urlParams.toString());
    }

    function setLanguage(newLang) {
        if (!["de", "fr", "en"].includes(lang)) return;
        urlParams.set("lang", newLang);
        window.location.replace("/approval?" + urlParams.toString());
    }
    let t = {};

    const uuid = urlParams.get("uuid");
    const partyNum = parseInt(urlParams.get("pnum"));

    let soloMode = false;
    let otp = "";

    let status = {};
    let chatMessages = [];
    let approval = false;
    let selectedMsg;
    let comment;

    onMount(() => {
        fetch("/static/i18n.json")
            .then((res) => res.json())
            .then((j) => (t = j[lang]));
        fetch(
            "/api/status?" +
                new URLSearchParams({
                    uuid,
                }),
        )
            .then((res) => res.json())
            .then((j) => {
                console.log(j);
                if (j.neg_info[3] !== 2) {
                    alert(t.appWrongPhase);
                    return;
                }
                soloMode = j.neg_info[4];
                status.topic = j.neg_info[1];
                status.partyInfo = JSON.parse(j.neg_info[2])[partyNum - 1];
                console.log(JSON.parse(j.neg_info[2]));
                checkMessages();
            });
    });

    function checkMessages() {
        console.log("checking messages...");
        fetch(
            "/api/llmlog?" +
                new URLSearchParams({
                    uuid,
                }),
        )
            .then((res) => res.json())
            .then((j) => {
                chatMessages = JSON.parse(j[1]);
                status.stage = j[2];
                if (status.stage === 1) {
                    setTimeout(checkMessages, 5000);
                }
            });
    }

    function checkForNegotiationStart() {
        fetch(
            "/api/status?" +
                new URLSearchParams({
                    uuid,
                }),
        )
            .then((res) => res.json())
            .then((j) => {
                if (j.neg_info[3] == 1) {
                    alert(t.appNegBegan);
                    window.location.replace(
                        `/overview?uuid=${uuid}&lang=${lang}`,
                    );
                }
            });
    }

    function setPartyApproval() {
        const fullPosition = {
            approval,
            msg: selectedMsg,
            comment: comment ? comment : "",
        };
        fetch(
            "/api/setapproval?" +
                new URLSearchParams({
                    uuid,
                    party_num: partyNum,
                    otp: otp.trim(),
                    approval: JSON.stringify(fullPosition),
                }),
        )
            .then((res) => res.json())
            .then((j) => {
                if (j.success) {
                    alert(t.appRedirect);
                    window.location.replace(`/overview?uuid=${uuid}`);
                } else {
                    alert(j.msg);
                }
            });
        setTimeout(checkForNegotiationStart, 2000);
    }
</script>

<main class="flex flex-col min-h-screen md:landscape:h-screen m-0 p-4 bg-base">
    <select
        class="absolute top-2 right-4 p-2 bg-base text-textcol border border-accent rounded-lg
		cursor-pointer"
        on:change={(e) => setLanguage(e.target.value)}
    >
        <option value="de" selected={lang === "de"}>Deutsch</option>
        <option value="fr" selected={lang === "fr"}>Français</option>
        <option value="en" selected={lang === "en"}>English</option>
    </select>
    {#if status.partyInfo}
        <div class="w-full max-h-1/4 min-h-1/4">
            <h1 class="font-bold text-2xl text-textcol">{t.appHeader}</h1>
            <p class="text-textcol mt-2">{t.ovUUID} {uuid}</p>
            <p class="font-bold mt-4 text-textcol">
                {t.appFor}
                {status.partyInfo.name} ({status.partyInfo.email})
            </p>
            <p class="font-bold mt-4 text-textcol">{t.ovTopicHeader}</p>
            <p class="text-textcol">{status.topic}</p>
        </div>

        <div
            class="flex flex-col md:landscape:flex-row w-full md:landscape:h-3/4 text-textcol"
        >
            <h2 class="font-bold mt-4 text-xl block md:landscape:hidden">
                {t.appHistoryHeader}
            </h2>
            <div
                class="flex flex-col w-full md:landscape:w-1/2 md:landscape:overflow-y-scroll md:landscape:h-full"
            >
                {#each chatMessages as msg}
                    <div
                        class="flex flex-col p-2"
                        class:disapprovalMsg={!approval &&
                            msg.party === status.partyInfo.name}
                        class:disapprovalMsgSelected={!approval &&
                            msg.msg === selectedMsg}
                        on:click={() => {
                            if (msg.party === status.partyInfo.name)
                                selectedMsg = msg.msg;
                        }}
                    >
                        <p class="font-bold">
                            {msg.party === status.partyInfo.name
                                ? t.appYourNeg
                                : t.ovNegOf + " " + msg.party}
                        </p>
                        <p>{msg.msg.replaceAll("#AGREE#", "").trim()}</p>
                        {#if msg.msg.trim() === "#AGREE#"}
                            <p class="text-green-200">
                                {t.ovNegAgreeMsg.replace("#PARTY#", msg.party)}
                            </p>
                        {:else if msg.msg.trim() === "#FAILED#"}
                            <p class="text-red-200">
                                {t.ovNegDeclineMsg.replace(
                                    "#PARTY#",
                                    msg.party,
                                )}
                            </p>
                        {/if}
                    </div>
                {/each}
            </div>
            <h2 class="font-bold mt-4 text-xl block md:landscape:hidden">
                {t.appRateHeader}
            </h2>
            <div
                class="flex flex-col w-full md:landscape:w-1/2 md:landscape:px-4 overflow-y-auto h-1/2 md:landscape:h-full"
            >
                <div
                    class="flex flex-row w-full justify-center items-center mb-4"
                >
                    <p class="text-red-200 flex-grow">{t.appRateAgree}</p>
                    <input
                        type="checkbox"
                        bind:checked={approval}
                        class="mx-2 toggle toggle-md bg-accent hover:bg-elevated"
                    />
                    <p class="text-green-200 flex-grow text-right">
                        {t.appRateDisagree}
                    </p>
                </div>
                {#if approval}
                    <p>{t.appAgreeP}</p>
                {:else}
                    <p>{t.appDisagreeP}</p>
                    {#if selectedMsg}
                        <h2 class="font-bold mt-2">{t.appSelectedMsg}</h2>
                        <p>{selectedMsg.replaceAll("#AGREE#", "").trim()}</p>
                        {#if selectedMsg.trim() === "#AGREE#"}
                            <p class="text-green-200">{t.appYourNegAgreed}</p>
                        {:else if selectedMsg.trim() === "#FAILED#"}
                            <p class="text-red-200">{t.appYourNegDeclined}</p>
                        {/if}
                        <h2 class="font-bold mt-2">{t.appCommentHeader}</h2>
                        <textarea
                            class="p-2 rounded-md w-full h-28 bg-elevated text-textcol placeholder-textcol"
                            placeholder={t.appCommentPH}
                            bind:value={comment}
                            maxlength="500"
                        ></textarea>
                    {/if}
                {/if}
                {#if !soloMode}
                    <h3 class="font-bold mt-4">{t.posAuth}</h3>
                    <p class="mt-2">{t.posAuthP}</p>
                    <input
                        type="password"
                        class="p-2 rounded-md w-full h-12 bg-elevated text-textcol placeholder-textcol mt-2"
                        placeholder={t.posAuthPH}
                        bind:value={otp}
                    />
                {/if}
                {#if approval}
                    <button
                        class="my-2 w-42 place-self-center p-2 rounded-md border-green-800 border-2 bg-green-800 text-white hover:bg-green-400 cursor-pointer flex items-center"
                        on:click={setPartyApproval}
                    >
                        <i class="fa-solid fa-circle-check text-white"></i>
                        <p class="ml-2">{t.appAgreeBtn}</p>
                    </button>
                {:else if selectedMsg}
                    <button
                        class="my-2 w-42 place-self-center p-2 rounded-md border-red-800 border-2 bg-red-800 text-white hover:bg-red-400 cursor-pointer flex items-center"
                        on:click={setPartyApproval}
                    >
                        <i class="fa-solid fa-circle-xmark text-white"></i>
                        <p class="ml-2">{t.appDisagreeBtn}</p>
                    </button>
                {/if}
            </div>
        </div>
    {:else}
        <p>{t.ovLoading}</p>
    {/if}
</main>
