<script>
	import { onMount } from "svelte";
	let prompt;
	onMount(() => {
		fetch(
			"/api/getprompt"
		).then(res => res.json()).then(j => prompt = j.prompt)
	})
	function setPrompt() {
		fetch(
			"/api/setprompt",
			{
				method: "POST",
				body: JSON.stringify({
					prompt
				})
			}
		).then(res => res.json()).then(j => alert(j.msg))
	}
</script>

<main>
	<h1 class="font-xl font-bold">System-Prompt</h1>
	<textarea class="rounded-md p-2 w-full h-64" bind:value={prompt}></textarea>
	<button class="rounded-md p-2 border border-black"
	on:click={setPrompt}>
		Prompt setzen
	</button>
</main>